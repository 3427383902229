import { Spinner } from "components";
import { Modal } from "components/Modal";
import { useEvents, useMembers, useSemesters } from "hooks";
import { useEffect, useState } from "react";
import { IMember, ISemester } from "types";
import { formatDate } from "utils/dates";
import { MemberForm } from "./Members";

export const InactiveMembers = () => {
  const semesters = useSemesters();
  const members = useMembers();
  const events = useEvents();

  const [modalActive, setModalActive] = useState(false);
  const [editMember, setEditMember] = useState<IMember | undefined>();
  const [semester, setSemester] = useState<ISemester | undefined>();

  const getFilteredEvents = () => {
    if (!events) return null;
    let filteredEvents = events.filter(
      (event) => event.type && event.type === "Øvelse"
    );
    const lastThree = filteredEvents.slice(0, 3);
    const today = new Date();
    const thisYear = today.getFullYear();
    const semesterStart =
      today <= new Date(thisYear, 6, 31)
        ? new Date(thisYear, 0, 1)
        : new Date(thisYear, 7, 1);
    filteredEvents = filteredEvents.filter(
      (event) => event.date > semesterStart
    );
    filteredEvents = filteredEvents.length >= 3 ? filteredEvents : lastThree;
    return filteredEvents;
  };

  const filteredEvents = getFilteredEvents();

  const inactiveMembers = members
    ?.map((member) => {
      let totalAbsent = 0;
      let absentInRowWithoutMessage = 0;
      let maxAbsentInRowWithoutMessage = 0;
      let lastPresent: Date | null = null;
      if (filteredEvents) {
        filteredEvents
          .filter((event) => event.date >= member.createdAt)
          .forEach((event) => {
            if (!event.attendants || !event.attendants[member.id]) {
              totalAbsent += 1;

              if (!event.nonAttendants || !event.nonAttendants[member.id]) {
                absentInRowWithoutMessage += 1;

                if (absentInRowWithoutMessage > maxAbsentInRowWithoutMessage) {
                  maxAbsentInRowWithoutMessage = absentInRowWithoutMessage;
                }
              } else {
                absentInRowWithoutMessage = 0;
              }
            } else {
              absentInRowWithoutMessage = 0;
              if (!lastPresent) {
                lastPresent = event.date;
              }
            }
          });
      }

      const inactive = maxAbsentInRowWithoutMessage >= 3 || totalAbsent >= 6;

      return {
        ...member,
        inactive,
        lastPresent,
        absentInRow: maxAbsentInRowWithoutMessage,
        totalAbsent,
      };
    })
    .filter((m) => m.inactive)
    .sort((a, b) => b.totalAbsent - a.totalAbsent)
    .sort((a, b) => b.absentInRow - a.absentInRow)
    .sort((a, b) => {
      if (!b.lastPresent && !a.lastPresent) {
        return 0;
      }
      if (!b.lastPresent) {
        return 1;
      }
      if (!a.lastPresent) {
        return -1;
      }
      return a.lastPresent - b.lastPresent;
    });

  const handleModalClose = () => {
    setModalActive(false);
  };

  useEffect(() => {
    if (semesters) {
      setSemester(semesters[semesters.length - 1]);
    }
  }, [semesters]);

  const loaded = !!events && !!members;

  return (
    <>
      <Modal active={modalActive} onClose={handleModalClose}>
        <>
          {!!editMember && (
            <MemberForm member={editMember} onSubmit={handleModalClose} />
          )}
        </>
      </Modal>
      <div className="content">
        <h1>Inaktive medlemmer</h1>
        {!loaded && <Spinner />}
        {loaded &&
          "Medlemskapet til de følgende kormedlemmene er regnet som avsluttet jf. korets vedtekter (§3.5)."}
        {loaded && !!inactiveMembers?.length && (
          <table className="table-full-width table-hor-lines-between">
            <thead>
              <tr>
                <th>Navn</th>
                <th>Siste øvelse</th>
                <th className="desktop-only">Fravær dette semester</th>
                <th className="desktop-only">Fravær på rad</th>
                {semester && (
                  <th className="desktop-only">Betalt semesteravgift</th>
                )}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {inactiveMembers.map((member) => (
                <tr key={member.id}>
                  <td>
                    {member.firstName} {member.lastName}
                  </td>
                  <td>
                    {member.lastPresent
                      ? formatDate(member.lastPresent)
                      : "Ingen dette semesteret"}
                  </td>
                  <td className="desktop-only">{member.totalAbsent}</td>
                  <td className="desktop-only">{member.absentInRow}</td>
                  {semester && (
                    <td className="desktop-only">
                      {semester.payees && semester.payees[member.id]
                        ? "Ja"
                        : "Nei"}
                    </td>
                  )}
                  <td>
                    <button
                      className="btn btn-small"
                      onClick={() => {
                        setEditMember(member);
                        setModalActive(true);
                      }}
                    >
                      <i className="fas fa-edit" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};
