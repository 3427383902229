import { useState } from "react";
import * as XLSX from "xlsx";

import { useEvents, useMembers } from "hooks";
import { formatDate } from "utils/dates";
import { Spinner } from "components";

export const AttendanceOverview = () => {
  const members = useMembers();
  const events = useEvents();

  const defaultStartDate = new Date();
  defaultStartDate.setHours(0, 0, 0, 0);
  defaultStartDate.setDate(defaultStartDate.getDate() - 30);
  const [startDate, setStartDate] = useState(defaultStartDate);

  const defaultEndDate = new Date();
  defaultEndDate.setHours(0, 0, 0, 0);
  defaultEndDate.setDate(defaultEndDate.getDate() + 1);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const [filter, setFilter] = useState("");

  const isMatch = (filter: string, name: string) => {
    const regex = new RegExp("(.*)" + filter + "(.*)", "i");
    return regex.test(name);
  };

  const filteredEvents = events?.filter(
    (event) => event.date > startDate && event.date < endDate
  );

  const filteredMembers = members?.filter(
    (member) =>
      member.active &&
      (!filter || isMatch(filter, member.firstName + " " + member.lastName))
  );

  const handleDownload = () => {
    if (!filteredMembers || !filteredEvents) return;
    const presence = filteredMembers.map((member) => {
      const row: { [key: string]: string | number } = {
        Etternavn: member.lastName,
        Fornavn: member.firstName,
        uid: member.id,
      };

      filteredEvents.forEach((event) => {
        let status = -1;
        if (event.attendants && event.attendants[member.id]) {
          status = 1;
        } else if (event.nonAttendants && event.nonAttendants[member.id]) {
          status = 0;
        }
        row[`${formatDate(event.date)} - ${event.title}`] = status;
      });

      return row;
    });

    const presenceSheet = XLSX.utils.json_to_sheet(presence, {
      header: ["uid", "Fornavn", "Etternavn"].concat(
        filteredEvents.map(
          (event) => `${formatDate(event.date)} - ${event.title}`
        )
      ),
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, presenceSheet, "Oppmøte");
    XLSX.writeFile(wb, "yadahreg-oppmøte.xlsx", {
      bookType: "xlsx",
    });
  };

  return (
    <div className="content">
      {(!members || !events) && <Spinner />}
      {!!members && !!events && (
        <>
          <button className="btn" onClick={handleDownload}>
            Last ned som Excel
          </button>
          <div className="row">
            <div className="col-half">
              <label htmlFor="startDate">Startdato</label>
              <input
                name="startDate"
                value={startDate.toISOString().split("T")[0]}
                onChange={(e) => setStartDate(new Date(e.currentTarget.value))}
                type="date"
              />
            </div>

            <div className="col-half">
              <label htmlFor="endDate">Sluttdato</label>
              <input
                name="endDate"
                value={endDate.toISOString().split("T")[0]}
                onChange={(e) => setEndDate(new Date(e.currentTarget.value))}
                type="date"
              />
            </div>
          </div>
          <input
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            name="filter"
            type="text"
            placeholder="Søk..."
          />
          <div className="table-scroll-container">
            <table className="table-full-width table-hor-lines-between">
              <thead>
                <tr>
                  <th>Navn</th>
                  {filteredEvents?.map((event) => (
                    <th key={event.id}>
                      <p title={`${event.title} (${event.type})`}>
                        {formatDate(event.date)}
                      </p>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <i>Antall oppmøtte</i>
                  </td>
                  {filteredEvents?.map((event) => (
                    <td key={event.id}>
                      {event.attendants
                        ? Object.keys(event.attendants).length
                        : 0}
                    </td>
                  ))}
                </tr>
                {filteredMembers?.map((member) => (
                  <tr key={member.id}>
                    <td>
                      {member.firstName} {member.lastName}
                    </td>
                    {filteredEvents?.map((event) => (
                      <td key={event.id}>
                        {event.attendants && member.id in event.attendants
                          ? "Y"
                          : event.nonAttendants &&
                            member.id in event.nonAttendants
                          ? "N"
                          : ""}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};
